.intersection-observer-div {
      position: fixed;
      top: 35%;
      left: 0;
      width: 100%;
      height: 1%;
      border: 2px solid white;
      z-index: 1;

}



.feed {

      min-height: 100vh;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
      margin-left: auto;
      margin-right: auto;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */

}

.feed::-webkit-scrollbar {
      display: none;
}


.feed h1 {
      position: fixed;
      margin-top: calc(20px + 10vh);
      margin-left: calc(20px + 2vw);
      top: 0;
      left: 0;
      font-style: italic;
      mix-blend-mode: screen;
      width: 40%;
      letter-spacing: -3px;
      font-size: 4rem;

}

.feed-title {
      font-size: 5rem;

}

.track-title-display {
      margin-top: 20rem;
      font-size: 3rem !important;
      letter-spacing: -1px !important;
      color: #FF90E8;
      line-height: 3.3rem;
}


.feed-container {
      display: grid;
      grid-template-columns: 1fr;
      margin-top: 15rem;
      gap: 40px;
      z-index: 1;
      background-color: white;

}

.feed-player-container {
      display: flex;
      justify-content: center;
      align-items: center;

}




.react-player {
      width: 80%;
      box-shadow: 10px 10px 1px #FF90E8;
      border-radius: 80px;
      margin-left: 30rem;
      margin-top: 15rem;

}

.radio-station-img {
      height: 9rem;
      width: 9rem;
      position: fixed;

}

.img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 13rem;
      width: 13rem;
      background-color: transparent;
      position: fixed;
      margin-left: 15%;
      margin-top: 20rem;
      border-radius: 50%;
      border: 15px dotted #FF90E8;
      animation: colorAnimation 10s infinite ease-in-out;
}

.rotate-div {
      height: 13rem;
      width: 13rem;
      background-color: transparent;
      position: fixed;
      margin-left: 15%;
      margin-top: 20rem;
      border-radius: 50%;
      border: 10px dotted #FF90E8;
      animation: rotate 50s infinite linear, colorAnimation 10s infinite ease-in-out;
}



.anchor:hover {
      cursor: pointer;
      z-index: 1;
}

@keyframes rotate {
      0% {
            transform: rotate(0deg);
      }

      100% {
            transform: rotate(360deg);
      }
}

@keyframes colorAnimation {
      0% {
            border-color: #FF90E8;
      }

      50% {
            border-color: white;
      }

      100% {
            border-color: #FF90E8;
      }
}

@media screen and (max-width: 912px) {

      .img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 8rem;
            width: 8rem;
            background-color: transparent;
            position: fixed;
            margin-left: 15%;
            margin-top: 20rem;
            border-radius: 50%;
            border: 15px dotted #FF90E8;
            animation: colorAnimation 10s infinite ease-in-out;
      }

      .rotate-div {
            height: 8rem;
            width: 8rem;
            background-color: transparent;
            position: fixed;
            margin-left: 15%;
            margin-top: 20rem;
            border-radius: 50%;
            border: 10px dotted #FF90E8;
            animation: rotate 50s infinite linear, colorAnimation 10s infinite ease-in-out;
      }

      .radio-station-img {
            height: 5rem;
            width: 5rem;
            position: fixed;

      }

      .feed-container {
            display: grid;
            grid-template-columns: 1fr;
            margin-top: 15rem;
            gap: 40px;
            z-index: 1;
            background-color: white;
            background-color: red;

      }

      .feed-player-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: calc((4rem + 8vw) * -1);
      }



}

@media screen and (max-width: 614px) {
      .feed h1 {
            position: fixed;
            margin-top: calc(20px + 10vh);
            margin-left: calc(20px + 2vw);
            top: 0;
            left: 0;
            font-style: italic;
            mix-blend-mode: screen;
            width: 100%;
            letter-spacing: 0;
            font-size: 2rem;

      }

      .break-title {
            display: none;
      }


      .radio-station-img {
            display: none;

      }

      .img-container {
            display: none;
      }

      .rotate-div {
            display: none;
      }

      .feed-player-container {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow-x: hidden;
            margin-top: 10rem;

      }

      .react-player {
            width: 80vw !important;
            height: 20rem !important;
            box-shadow: 5px 5px 1px #FF90E8;
            border-radius: 80px;
            margin-top: 5rem;
            margin-left: 25vw;

      }


}