.hamburger {
      height: 30px;
      width: 35px;
      position: fixed;
      justify-content: space-evenly;
      align-items: center;
      gap: 2px;
      flex-direction: column;
      left: 85vw;
      top: 3vh;
      display: flex;
      z-index: 10;
      visibility: hidden;
}

.hamburger-stroke {

      height: 5px;
      width: 80%;
      background-color: transparent;
      border: 1.5px solid #FF90E8;
      border-radius: 10px;
      transition: transform 0.3s ease-in-out opacity 0.3s ease-in-out;
}

.hamburger:hover {
      cursor: pointer;
}

.stroke-1 {
      position: absolute;
      transform: rotate(45deg);
      background-color: #FF90E8;


}

.stroke-2 {
      opacity: 0;
}

.stroke-3 {
      position: absolute;
      transform: rotate(-45deg);
      background-color: #FF90E8;

}

@media screen and (max-width: 512px) {
      .hamburger {

            visibility: visible;
      }
}