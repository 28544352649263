* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: src("./noise.svg");
  width: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  position: relative;
  background: #1200FF;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
}


@media screen and (max-width: 912px) {

  h1 {

    font-size: 3rem !important;
    letter-spacing: 0 !important;

  }



}




@media screen and (max-width: 414px) {
  h1 {

    font-size: 3rem !important;
    letter-spacing: 0;

  }


}