nav.navbar {
      position: relative;
      height: calc(2rem + 4vh);
      width: 100vw;
      background-color: #FF90E8;
      position: fixed;
      top: 0;
      left: 0;
      font-family: "Nohemi Medium";
      border: 1.5px solid black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 2;

}





@media screen and (max-width: 1180px) {
      nav.navbar {
            max-width: 100vw !important;

      }
}

@media screen and (max-width: 512px) {

      nav.navbar {
            position: relative;
            height: 100vh;
            width: 65vw !important;
            flex-direction: column;
            background-color: rgba(18, 0, 255, 0.6);
            backdrop-filter: blur(10px);
            position: fixed;
            top: 0;
            left: -80vw;
            margin-left: -60px;
            font-family: "Nohemi Medium";
            border: 1.5px solid black;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            z-index: 2;
            transition: left 0.4s ease-in-out;

      }

      .navbar-open {
            left: 10% !important;
      }

}

@media screen and (max-width: 414px) {

      nav.navbar {
            position: relative;
            height: 100vh;
            width: 50vw;
            flex-direction: column;
            background-color: rgba(18, 0, 255, 0.6);
            backdrop-filter: blur(10px);
            position: fixed;
            top: 0;
            left: -50%;
            font-family: "Nohemi Medium";
            border: 1.5px solid black;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            z-index: 2;
            transition: left 0.4s ease-in-out;

      }

      .navbar-open {
            left: 10% !important;
      }

}