.home {
      height: 100%;
      width: 100vw;
      position: relative;
      background-image: linear-gradient(rgba(134, 138, 246, .3) .1em, transparent .08rem), linear-gradient(90deg, rgba(134, 138, 246, .3) .08rem, transparent .1em);
      background-size: 19.8vw 19.8vw;
}

.first-section {
      position: relative;
      height: calc(96vh - 1rem);
      width: 100vw;
      max-height: 900px;
}


/*positioning decoration */

.clouds {
      position: absolute;
      opacity: 1;
      scale: 1;
}

.clouds:nth-of-type(1) {
      position: absolute;
      margin-top: 17rem;
      margin-left: 4rem;
      filter: blur(40px);


}

.clouds:nth-of-type(2) {
      position: absolute;
      margin-top: 40rem !important;
      margin-left: 52rem !important;
      rotate: - 40deg !important;
      filter: blur(40px);


}

@keyframes firstcloud {
      0% {
            scale: 0.5
      }

      50% {
            scale: .52
      }

      100% {
            scale: 0.5
      }
}

.clouds:nth-of-type(2) {
      rotate: 30deg;
      margin-top: 24rem;
      margin-left: 47rem;
}

.pink-field-div {
      position: absolute;
      font-size: 2rem;
      margin-left: 35rem;
      margin-top: 16rem;
      font-family: "Nohemi Bold";
      background-color: #FF90E8;
      width: calc(40rem + 5vw);
      text-align: center;
      border-radius: 40px;
      padding: 10px 0 10px 0;
      border: 2px solid black;
      box-shadow: 1px 6px 1px black;
}

.colorfields-section {
      position: absolute;
      background-color: transparent;
      width: 32rem;
      height: 16rem;
      margin-left: 44rem;
      margin-top: 22rem;
}

.field {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid black;
      width: calc(7rem + 2vw);
      height: 4rem;
      border-radius: 40px;
      font-size: 1.7rem;
      font-family: "Nohemi Bold";
      box-shadow: 1px 4px 1px black;
}

.field:nth-of-type(1) {
      margin-left: -2rem;
      background-color: #EE8F9E;
      margin-top: 5vh;
      rotate: 30deg;
}

.field:nth-of-type(2) {
      margin-left: 7rem;
      margin-top: 2rem;
      rotate: 20deg;
      background-color: #30A69A;
}

.field:nth-of-type(3) {
      margin-left: -4rem;
      margin-top: 8rem;
      background-color: #CFED90;
      rotate: 15deg;
}

.field:nth-of-type(4) {
      margin-left: 7rem;
      margin-top: 9rem;
      background-color: #90CCED;
      rotate: -33deg;
}

@media screen and (max-width: 1180px) {


      .home {

            max-width: 1180px !important;
      }

      .pink-field-div {
            position: absolute;
            font-size: 1.6rem;
            margin-left: calc(12rem + 18vw);
            margin-top: calc(15.5rem + 8vh);
            background-color: #FF90E8;
            width: calc(35rem + 5vw);
      }

      .colorfields-section {
            position: absolute;
            background-color: transparent;
            width: calc(14rem + 15vw);
            height: 12rem;
            margin-left: calc(12rem + 25vw);
            margin-top: 25rem;
      }

      .field {

            width: calc(4rem + 4vw);
            height: 3rem;
            border-radius: 40px;
            font-size: 1.3rem;
            font-family: "Nohemi Bold";
            box-shadow: 1px 4px 1px black;
      }


}

@media screen and (max-width: 1024px) {


      .pink-field-div {
            position: absolute;
            font-size: 1.6rem;
            margin-left: calc(10rem + 15vw);
            margin-top: calc(12rem + 5vh);
            background-color: #FF90E8;
            width: calc(35rem + 5vw);
      }

      .colorfields-section {
            position: absolute;
            background-color: transparent;
            width: calc(14rem + 15vw);
            height: 16rem;
            margin-left: calc(20rem + 25vw);
            margin-top: 22rem;
      }

      .field {

            width: calc(4rem + 4vw);
            height: 3rem;
            border-radius: 40px;
            font-size: 1.3rem;
            font-family: "Nohemi Bold";
            box-shadow: 1px 4px 1px black;
      }

}

@media screen and (max-width: 912px) {


      .first-section {
            position: relative;
            height: calc(100vh - 1rem);
            width: 100vw;
            max-height: 100vh;
      }


      .pink-field-div {
            position: absolute;
            font-size: 2.2rem;
            margin-left: calc(4rem + 1vw);
            margin-top: calc(23rem + 5vh);
            background-color: #FF90E8;
            width: calc(35rem + 29vw);
            padding: 20px;
      }

      .colorfields-section {
            position: absolute;
            background-color: transparent;
            width: calc(14rem + 15vw);
            height: 16rem;
            margin-left: calc(20rem + 25vw);
            margin-top: 33rem;
      }

      .field {

            width: calc(8rem + 8vw);
            height: 3rem;
            border-radius: 40px;
            font-size: 2rem;
            font-family: "Nohemi Bold";
            box-shadow: 1px 4px 1px black;
            padding: 40px;
      }

      .field:nth-of-type(1) {
            margin-left: -5rem;
            background-color: #EE8F9E;
            margin-top: 8vh;
            rotate: 30deg;
      }

      .field:nth-of-type(2) {
            margin-left: 7rem;
            margin-top: 4rem;
            rotate: 20deg;
            background-color: #30A69A;
      }

      .field:nth-of-type(3) {
            margin-left: -8rem;
            margin-top: 15rem;
            background-color: #CFED90;
            rotate: 15deg;
      }

      .field:nth-of-type(4) {
            margin-left: 7rem;
            margin-top: 14rem;
            background-color: #90CCED;
            rotate: -33deg;
      }

}

@media screen and (max-width: 768px) {


      .pink-field-div {
            position: absolute;
            font-size: 1.6rem;
            margin-left: calc(5rem + 10vw);
            margin-top: calc(23rem + 5vh);
            background-color: #FF90E8;
            width: calc(35rem + 5vw);
      }

      .colorfields-section {
            position: absolute;
            background-color: transparent;
            width: calc(14rem + 18vw);
            height: 16rem;
            margin-left: calc(15rem + 25vw);
            margin-top: 33rem;
      }

      .field {

            width: calc(4rem + 8vw);
            height: 3rem;
            border-radius: 40px;
            font-size: 1.3rem;
            font-family: "Nohemi Bold";
            box-shadow: 1px 4px 1px black;
      }

}


@media screen and (max-width: 720px) {


      .pink-field-div {
            position: absolute;
            font-size: 1.1rem;
            margin-left: calc(5rem + 10vw);
            margin-top: calc(13rem + 5vh);
            background-color: #FF90E8;
            width: calc(25rem + 5vw);
      }

      .colorfields-section {
            position: absolute;
            background-color: transparent;
            width: calc(14rem + 15vw);
            height: 16rem;
            margin-left: calc(15rem + 25vw);
            margin-top: 19rem;
      }

      .field {

            width: calc(4rem + 4vw);
            height: 3rem;
            border-radius: 40px;
            font-size: 1.1rem;
            font-family: "Nohemi Bold";
            box-shadow: 1px 4px 1px black;
      }

      .field:nth-of-type(1) {
            margin-left: -2rem;
            background-color: #EE8F9E;
            margin-top: 2vh;
            rotate: 30deg;
      }

      .field:nth-of-type(2) {
            margin-left: 5rem;
            margin-top: 1rem;
            rotate: 20deg;
            background-color: #30A69A;
      }

      .field:nth-of-type(3) {
            margin-left: -3rem;
            margin-top: 6rem;
            background-color: #CFED90;
            rotate: 15deg;
      }

      .field:nth-of-type(4) {
            margin-left: 5rem;
            margin-top: 6rem;
            background-color: #90CCED;
            rotate: -33deg;
      }

}


@media screen and (max-width: 414px) {
      .pink-field-div {
            position: absolute;
            font-size: 1rem;
            margin-left: calc(0rem + 4vw);
            margin-top: calc(18rem + 5vh);
            background-color: #FF90E8;
            width: calc(23rem + 5vw);
      }

      .colorfields-section {

            margin-left: calc(0rem + 25vw);
            margin-top: 34rem;
      }

      .field {

            width: calc(8rem + 1vw);
            height: 1rem;
            border-radius: 40px;
            font-size: 1.1rem;
            font-family: "Nohemi Bold";
            box-shadow: 1px 4px 1px black;
            padding: 25px;
      }

      .field:nth-of-type(1) {
            margin-left: -2rem;
            background-color: #EE8F9E;
            margin-top: 2vh;
            rotate: 30deg;
      }

      .field:nth-of-type(2) {
            margin-left: 5rem;
            margin-top: 1rem;
            rotate: 20deg;
            background-color: #30A69A;
      }

      .field:nth-of-type(3) {
            margin-left: -3rem;
            margin-top: 6rem;
            background-color: #CFED90;
            rotate: 15deg;
      }

      .field:nth-of-type(4) {
            margin-left: 5rem;
            margin-top: 6rem;
            background-color: #90CCED;
            rotate: -33deg;
      }

}

@media screen and (max-width: 390px) {
      .pink-field-div {
            position: absolute;
            font-size: 1rem;
            margin-left: calc(0rem + 4vw);
            margin-top: calc(18rem + 5vh);
            background-color: #FF90E8;
            width: calc(21rem + 5vw);
      }



}

@media screen and (max-width: 375px) {
      .pink-field-div {
            position: absolute;
            font-size: 1rem;
            margin-left: calc(0rem + 4vw);
            margin-top: calc(18rem + 5vh);
            background-color: #FF90E8;
            width: calc(18rem + 5vw);
      }

      .colorfields-section {

            margin-left: calc(0rem + 25vw);
            margin-top: 28rem;
      }

      .field {

            width: calc(5rem + 1vw);
            height: 1rem;
            border-radius: 40px;
            font-size: 0.9rem;
            font-family: "Nohemi Bold";
            box-shadow: 1px 4px 1px black;
            padding: 25px;
      }

      .field:nth-of-type(1) {
            margin-left: -2rem;
            background-color: #EE8F9E;
            margin-top: 2vh;
            rotate: 30deg;
      }

      .field:nth-of-type(2) {
            margin-left: 5rem;
            margin-top: 1rem;
            rotate: 20deg;
            background-color: #30A69A;
      }

      .field:nth-of-type(3) {
            margin-left: -3rem;
            margin-top: 6rem;
            background-color: #CFED90;
            rotate: 15deg;
      }

      .field:nth-of-type(4) {
            margin-left: 5rem;
            margin-top: 6rem;
            background-color: #90CCED;
            rotate: -33deg;
      }

}

@media screen and (max-width: 320px) {


      .pink-field-div {
            position: absolute;
            font-size: 0.9rem;
            margin-left: calc(0rem + 4vw);
            margin-top: calc(11rem + 5vh);
            background-color: #FF90E8;
            width: calc(18rem + 5vw);
      }

      .colorfields-section {

            margin-left: calc(0rem + 25vw);
            margin-top: 19rem;
      }

      .field {

            width: calc(5rem + 1vw);
            height: 0.9rem;
            border-radius: 40px;
            font-size: 0.8rem;
            font-family: "Nohemi Bold";
            box-shadow: 1px 4px 1px black;
            padding: 20px;
      }

      .field:nth-of-type(1) {
            margin-left: -1rem;
            background-color: #EE8F9E;
            margin-top: 2vh;
            rotate: 30deg;
      }

      .field:nth-of-type(2) {
            margin-left: 5rem;
            margin-top: 1rem;
            rotate: 20deg;
            background-color: #30A69A;
      }

      .field:nth-of-type(3) {
            margin-left: -1.5rem;
            margin-top: 6rem;
            background-color: #CFED90;
            rotate: 15deg;
      }

      .field:nth-of-type(4) {
            margin-left: 5rem;
            margin-top: 6rem;
            background-color: #90CCED;
            rotate: -33deg;
      }


}

@media (aspect-ratio: 820/1180) {

      .first-section {
            position: relative;
            height: calc(96vh - 1rem);
            width: 100vw;
            max-height: 100%;
      }



      .pink-field-div {
            position: absolute;
            font-size: 1.8rem;
            margin-left: calc(2rem + 10vw);
            margin-top: calc(18rem + 5vh);
            background-color: #FF90E8;
            width: calc(34rem + 19vw);
            padding: 20px;
      }

      .colorfields-section {
            position: absolute;
            background-color: transparent;
            width: calc(14rem + 15vw);
            height: 16rem;
            margin-left: calc(12rem + 25vw);
            margin-top: calc(10rem + 30vh);
      }

      .field {

            width: calc(6rem + 8vw);
            height: calc(2rem + 3.5vh);
            border-radius: 40px;
            font-size: 1.6rem;
            font-family: "Nohemi Bold";
            box-shadow: 1px 4px 1px black;
      }

      .field:nth-of-type(1) {
            margin-left: -8rem;
            background-color: #EE8F9E;
            margin-top: 2vh;
            rotate: 30deg;
      }

      .field:nth-of-type(2) {
            margin-left: 5rem;
            margin-top: 1rem;
            rotate: 20deg;
            background-color: #30A69A;
      }

      .field:nth-of-type(3) {
            margin-left: -6rem;
            margin-top: calc(3rem + 9vh);
            background-color: #CFED90;
            rotate: 15deg;
      }

      .field:nth-of-type(4) {
            margin-left: 5rem;
            margin-top: calc(3rem + 12vh);
            background-color: #90CCED;
            rotate: -33deg;
      }

}