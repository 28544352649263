.waves {
      position: absolute;
      margin-top: 4rem;
      overflow: hidden;
      z-index: -1;
      margin-left: -10px;

}

.wave {
      position: relative;
      margin-top: 0.1rem;
      margin-left: -15rem;
      width: 30rem;
      height: 2.5rem;
      background: transparent;
      overflow: hidden;


}

.wave::before {
      content: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa";
      position: absolute;
      color: transparent;
      font-size: 3rem;
      text-decoration-style: wavy;
      text-decoration-color: #FF90E8;
      text-decoration-line: line-through;
      transform: translateY(-35%);

}

.wave1,
.wave2,
.wave3 {
      z-index: -1;
}

.wave1::before {
      animation: animate1 25s linear infinite;
}

.wave2::before {
      animation: animate1 20s linear infinite;
}

.wave3::before {
      animation: animate1 22s linear infinite;
}

.wave4::before {
      animation: animate1 27s linear infinite;
}

.wave5::before {
      animation: animate1 23s linear infinite;
}

@keyframes animate1 {
      0% {
            transform: translateX(-7%);
      }

      100% {
            transform: translateX(-15%);
      }
}



@media screen and (max-width: 1180px) {

      .waves {
            position: absolute;
            margin-top: 4rem;
            overflow: hidden;
            z-index: -1;
            margin-left: -10px;

      }

      .wave {
            position: relative;
            margin-top: 0;
            margin-left: -8rem;
            width: 30rem;
            height: 2.5rem;
            background: transparent;
            overflow: hidden;

      }

      .wave::before {
            content: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa";
            position: absolute;
            color: transparent;
            font-size: 3rem;
            text-decoration-style: wavy;
            text-decoration-color: #FF90E8;
            text-decoration-line: line-through;
            transform: translateY(-35%);

      }

}


@media screen and (max-width: 720px) {


      .waves {
            position: absolute;
            margin-top: 4rem;
            overflow: hidden;
            z-index: -1;
            margin-left: -10px;

      }

      .wave {
            position: relative;
            margin-top: 0;
            margin-left: -12rem;
            width: 30rem;
            height: 1.5rem;
            background: transparent;
            overflow: hidden;

      }

      .wave::before {
            content: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa";
            position: absolute;
            color: transparent;
            font-size: 2rem;
            text-decoration-style: wavy;
            text-decoration-color: #FF90E8;
            text-decoration-line: line-through;
            transform: translateY(-35%);

      }
}

@media screen and (max-width: 414px) {

      .waves {
            position: absolute;
            margin-top: 6rem;
            overflow: hidden;
            z-index: -1;
            margin-left: -10px;

      }

      .wave {
            position: relative;
            margin-top: 0;
            margin-left: -20rem;
            width: 30rem;
            height: 1.4rem;
            background: transparent;
            overflow: hidden;

      }

      .wave::before {

            font-size: 1.8rem;
            text-decoration-style: wavy;
            text-decoration-color: #FF90E8;
            text-decoration-line: line-through;
            transform: translateY(-35%);

      }
}


@media screen and (max-width: 320px) {
      .waves {
            position: absolute;
            margin-top: 4rem;
            overflow: hidden;
            z-index: -1;
            margin-left: -10px;

      }

      .wave {
            position: relative;
            margin-top: 0;
            margin-left: -20rem;
            width: 30rem;
            height: 1.2rem;
            background: transparent;
            overflow: hidden;

      }

      .wave::before {

            font-size: 1.4rem;
            text-decoration-style: wavy;
            text-decoration-color: #FF90E8;
            text-decoration-line: line-through;
            transform: translateY(-35%);

      }

}