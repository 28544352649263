.second-section {
      position: relative;
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      max-height: 900px;
}

.second-section h2,
h2 {
      font-family: "Chopper";
      font-size: 50px;
      color: #ececec;

}

.second-title {
      opacity: 0;
}



.second-section_block {
      height: 1rem;
      background-color: #90CBED;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 30rem;
      border: 1px solid black;
      margin-top: 10px;
      margin-left: 5rem;

}

.text-section {
      margin-top: 2rem;
      opacity: 0;
}

.text-section p {
      margin-top: 30px;
      font-family: "Nohemi Bold";
      color: #ececec;
      line-height: 1.4;
      width: 20rem;
}

.second-section-title {

      margin-left: -4rem;
}


@media screen and (max-width: 912px) {

      .second-section {
            position: relative;
            height: 100vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 5rem;
      }


}

@media screen and (max-width: 768px) {

      .second-section h2,
      h2 {
            font-family: "Chopper";
            font-size: 40px;
            color: #ececec;

      }



}

@media screen and (max-width: 720px) {

      .second-section h2,
      h2 {
            font-family: "Chopper";
            font-size: 30px;
            color: #ececec;

      }

      .second-section {
            position: relative;
            height: 100vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 5rem;
      }



      .second-title {
            opacity: 0;
            margin-top: 2rem;
      }


      .second-section_block {
            height: 1rem;
            background-color: #90CBED;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            width: calc(11rem + 9vw);
            border: 1px solid black;
            margin-top: 10px;
            margin-left: 5rem;

      }

      .text-section {
            margin-top: 2rem;
            opacity: 0;
      }

      .text-section p {
            font-size: 0.8rem;

      }

      .second-section-title {

            margin-left: -4rem;
      }



}

@media screen and (max-width: 414px) {

      .second-section h2,
      h2 {
            font-family: "Chopper";
            font-size: 2rem;
            color: #ececec;
            margin-left: 2rem;

      }

      .second-section {
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 3rem;
      }



      .second-title {
            opacity: 0;
            margin-top: 2rem;
      }



      .second-section_block {
            height: 1rem;
            background-color: #90CBED;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            width: calc(11rem + 9vw);
            border: 1px solid black;
            margin-top: 10px;
            margin-left: 5rem;

      }

      .text-section {
            margin-top: 2rem;
            opacity: 0;
      }

      .text-section p {
            font-size: 0.8rem;

      }

      .second-section-title {

            margin-left: -4rem;
      }
}

@media screen and (max-width: 320px) {


      .second-section h2,
      h2 {
            font-family: "Chopper";
            font-size: 1.8rem;
            color: #ececec;
            margin-left: 2rem;

      }

      .second-section {
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 3rem;
      }



      .second-title {
            opacity: 0;
      }



      .second-section_block {
            height: 1rem;
            background-color: #90CBED;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            width: calc(11rem + 9vw);
            border: 1px solid black;
            margin-top: 10px;
            margin-left: 5rem;

      }

      .text-section {
            margin-top: 2rem;
            opacity: 0;
      }

      .text-section p {
            font-size: 0.7rem;
            width: 90%;
            padding-left: 10px;

      }

      .second-section-title {

            margin-left: -4rem;
      }


}