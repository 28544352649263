.third-section {
      position: relative;
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      max-height: 900px;


}

.third-section-text {
      position: relative;
      margin-left: 19rem;
}

.third-section-block {
      height: 1rem;
      width: 30rem;
      border: 1px solid black;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border: 1px solid black;
      margin-top: 10px;
      margin-left: 5rem;
      background-color: #30A69A;

}

.third-section-text p {
      margin-top: 30px;
      font-family: "Nohemi Bold";
      color: #ececec;
      line-height: 1.4;
      width: 25rem;
      margin-left: 3rem;
}

.discord-logo {
      position: absolute;
      scale: 0.5;
      margin-left: 25rem;
      margin-top: -4rem;
      rotate: 10deg;
}

.swirl-1 {
      position: absolute;
      scale: 0.8;
      margin-left: 26.5rem;
      rotate: 270deg;
      margin-top: -2rem;
}

.swirl-2 {
      position: absolute;
      scale: 0.8;
      margin-left: 32.6rem;
      margin-top: -1.7rem;
}

.learn-more-button {
      font-family: "Nohemi Bold";
      height: 4rem;
      width: 18rem;
      font-size: 1.5rem;
      border-radius: 40px;
      border: 1.5px solid black;
      background-color: rgb(118, 190, 228);
      box-shadow: 2px 4px 1px black;
      transition: all 0.3s ease;
}

.learn-more-button:hover {
      background-color: rgb(144, 204, 237);
      cursor: pointer;
}

.learn-more-button:active {
      box-shadow: inset 1px 1px 1px black;
}

.section-three-cloud {
      position: absolute;
      margin-left: -50rem;
      filter: blur(40px)
}

@media screen and (max-width: 768px) {
      .third-section-text {
            position: relative;
            margin-left: 0rem;
      }
}



@media screen and (max-width: 720px) {


      .third-section {
            margin-top: 6vh;

      }

      .third-section-block {
            height: 1rem;
            width: calc(12rem + 10vw);
            border: 1px solid black;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border: 1px solid black;
            margin-top: 10px;
            margin-left: 0;
            background-color: #30A69A;

      }

      .third-section-text {
            position: relative;
            margin-left: 0rem;
      }

      .discord-logo {
            position: absolute;
            scale: 0.3;
            margin-left: 25rem;
            margin-top: -4rem;
            rotate: 10deg;
      }

      .swirl-1 {
            position: absolute;
            scale: 0.6;
            margin-left: 27.5rem;
            rotate: 270deg;
            margin-top: -1rem;
      }

      .swirl-2 {
            position: absolute;
            scale: 0.6;
            margin-left: 31.6rem;
            margin-top: -1rem;
      }

      .learn-more-button {
            font-family: "Nohemi Bold";
            height: 3rem;
            width: 14rem;
            font-size: 1.2rem;
            border-radius: 40px;
            border: 1.5px solid black;
            background-color: rgb(118, 190, 228);
            box-shadow: 2px 4px 1px black;
            transition: all 0.3s ease;
      }
}

@media screen and (max-width: 414px) {
      .third-section {
            margin-top: 1vh;

      }

      .third-section-block {
            height: 1rem;
            width: calc(12rem + 10vw);
            border: 1px solid black;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border: 1px solid black;
            margin-top: 10px;
            margin-left: calc(14vw + 2rem);
            background-color: #30A69A;

      }

      .third-section-text {
            position: relative;
            margin-left: 0rem;
      }

      .discord-logo {
            position: absolute;
            scale: 0.5;
            margin-left: calc(4rem + 4vw);
            margin-top: 0;
            rotate: 10deg;
      }

      .swirl-1 {
            position: absolute;
            scale: 0.8;
            margin-left: calc(4rem + 8vw);
            rotate: 270deg;
            margin-top: 2rem;
      }

      .swirl-2 {
            position: absolute;
            scale: 0.8;
            margin-left: calc(12rem + 4vw);
            margin-top: 2rem;
      }

}

@media screen and (max-width: 390px) {
      .third-section {
            margin-top: 1vh;

      }

      .third-section-block {
            height: 1rem;
            width: calc(12rem + 10vw);
            border: 1px solid black;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border: 1px solid black;
            margin-top: 10px;
            margin-left: calc(14vw + 2rem);
            background-color: #30A69A;

      }

      .third-section-text {
            position: relative;
            margin-left: 1rem;
      }

      .third-section-text p {
            font-size: 0.8rem;
            width: 80%;
      }

      .discord-logo {
            position: absolute;
            scale: 0.4;
            margin-left: calc(4rem + 4vw);
            margin-top: 0;
            rotate: 10deg;
      }

      .swirl-1 {
            position: absolute;
            scale: 0.7;
            margin-left: calc(5rem + 8vw);
            rotate: 270deg;
            margin-top: 2rem;
      }

      .swirl-2 {
            position: absolute;
            scale: 0.7;
            margin-left: calc(11rem + 4vw);
            margin-top: 2rem;
      }

      .learn-more-button {
            font-family: "Nohemi Bold";
            height: 3rem;
            width: 13rem;
            font-size: 1.2rem;
            border-radius: 40px;
            border: 1.5px solid black;
            background-color: rgb(118, 190, 228);
            box-shadow: 2px 4px 1px black;
            transition: all 0.3s ease;
            margin-top: 3rem;
      }

}


@media screen and (max-width: 375px) {
      .third-section {
            margin-top: 1vh;

      }

      .third-section-block {
            height: 1rem;
            width: calc(12rem + 10vw);
            border: 1px solid black;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border: 1px solid black;
            margin-top: 10px;
            margin-left: calc(14vw + 2rem);
            background-color: #30A69A;

      }

      .third-section-text {
            position: relative;
            margin-left: 1rem;
      }

      .third-section-text p {
            font-size: 0.8rem;
            width: 80%;
      }

      .discord-logo {
            position: absolute;
            scale: 0.4;
            margin-left: calc(4rem + 4vw);
            margin-top: 0;
            rotate: 10deg;
      }

      .swirl-1 {
            position: absolute;
            scale: 0.7;
            margin-left: calc(5rem + 8vw);
            rotate: 270deg;
            margin-top: 2rem;
      }

      .swirl-2 {
            position: absolute;
            scale: 0.7;
            margin-left: calc(11rem + 4vw);
            margin-top: 2rem;
      }

      .learn-more-button {
            font-family: "Nohemi Bold";
            height: 3rem;
            width: 13rem;
            font-size: 1.2rem;
            border-radius: 40px;
            border: 1.5px solid black;
            background-color: rgb(118, 190, 228);
            box-shadow: 2px 4px 1px black;
            transition: all 0.3s ease;
            margin-top: 3rem;
      }

}

@media screen and (max-width: 320px) {
      .third-section {
            margin-top: 1vh;

      }

      .third-section-block {
            height: 1rem;
            width: calc(8rem + 10vw);
            border: 1px solid black;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border: 1px solid black;
            margin-top: 10px;
            margin-left: calc(14vw + 2rem);
            background-color: #30A69A;

      }

      .third-section-text {
            position: relative;
            margin-left: 0;
      }

      .third-section-text p {
            font-size: 0.8rem;
            width: 80%;
      }

      .discord-logo {
            position: absolute;
            scale: 0.3;
            margin-left: calc(4rem + 4vw);
            margin-top: -20px;
            rotate: 10deg;
      }

      .swirl-1 {
            position: absolute;
            scale: 0.6;
            margin-left: calc(5rem + 8vw);
            rotate: 270deg;
            margin-top: 2rem;
      }

      .swirl-2 {
            position: absolute;
            scale: 0.6;
            margin-left: calc(11rem + 4vw);
            margin-top: 2rem;
      }

      .learn-more-button {
            font-family: "Nohemi Bold";
            height: 3rem;
            width: 10.5rem;
            font-size: 1rem;
            border-radius: 40px;
            border: 1.5px solid black;
            background-color: rgb(118, 190, 228);
            box-shadow: 2px 4px 1px black;
            transition: all 0.3s ease;
            margin-top: 4rem;
      }

}


@media (aspect-ratio: 820/1180) {

      .third-section {
            position: relative;
            height: 100vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            max-height: 100vh;

      }

      .third-section-text {
            position: relative;
            margin-left: 0rem;
      }

      .third-section-block {
            height: 1rem;
            width: 30rem;
            border: 1px solid black;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border: 1px solid black;
            margin-top: 10px;
            margin-left: 5rem;
            background-color: #30A69A;

      }

      .third-section-text p {
            margin-top: 30px;
            font-family: "Nohemi Bold";
            color: #ececec;
            line-height: 1.4;
            width: 25rem;
            margin-left: 3rem;
            font-size: 1.2rem;
      }
}