.stations-container {
      height: 100%;
      width: 100vw;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      align-items: center;
      row-gap: calc(1rem + 8vw);
      column-gap: calc(0.8rem + 5vw);
      padding: calc(4rem + 8vw) 6rem calc(4rem + 8vw) 6rem;

}

.stations-container h1 {
      position: fixed;
      margin-top: calc(20px + 10vh);
      margin-left: calc(20px + 2vw);
      top: 0;
      left: 0;
      font-style: italic;
      width: 80%;
      letter-spacing: -3px;
      font-size: 4rem;
      color: white;
}

@media screen and (min-width: 1600px) {

      .stations-container {
            height: 100%;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-content: center;
            align-items: center;
            row-gap: calc(1rem + 5vw);
            column-gap: calc(0.8rem + 3vw);
            padding: calc(4rem + 8vw) 6rem calc(4rem + 8vw) 6rem;

      }

}

@media screen and (max-width: 820px) {
      .stations-container {
            height: 100%;
            width: 100vw;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-content: center;
            align-items: center;
            row-gap: calc(1rem + 8vw);
            column-gap: calc(0.8rem + 5vw);
            padding-top: calc(11rem + 4vw);

      }

}


@media screen and (max-width: 768px) {
      .stations-container {
            height: 100%;
            width: 100vw;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-content: center;
            align-items: center;
            row-gap: calc(1rem + 8vw);
            column-gap: calc(0.8rem + 5vw);
            padding-top: calc(11rem + 4vw);

      }

}

@media screen and (max-width: 720px) {


      .stations-container {
            height: 100vh;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-content: center;
            align-items: center;
            row-gap: calc(1rem + 8vw);
            column-gap: calc(0.8rem + 5vw);
            padding: calc(4rem + 8vw) 6rem calc(4rem + 8vw) 6rem;

      }

      .stations-container h1 {
            position: fixed;
            margin-top: calc(20px + 10vh);
            margin-left: calc(20px + 2vw);
            top: 0;
            left: 0;
            font-style: italic;
            width: 80%;
            letter-spacing: -3px;
            font-size: 3rem;
            color: white;
      }
}

@media screen and (max-width: 512px) {

      .stations-container {
            height: 100%;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: center;
            align-items: center;
            row-gap: calc(1rem + 8vw);
            column-gap: calc(0.8rem + 5vw);
            padding: calc(4rem + 8vw) 6rem calc(4rem + 8vw) 6rem;
            margin-top: 40%;

      }


}

@media screen and (max-width: 414px) {

      .stations-container {
            padding-left: 10px;
            padding-right: 10px;

      }


}

@media screen and (max-width: 360px) {

      .stations-container {
            padding-left: 10px;
            padding-right: 10px;

      }

      .stations-container h1 {
            position: fixed;
            margin-top: calc(20px + 10vh);
            margin-left: calc(20px + 2vw);
            top: 0;
            left: 0;
            font-style: italic;
            width: 80%;
            letter-spacing: 0px;
            font-size: 2rem;
            color: white;
      }
}

@media screen and (max-width: 320px) {

      .stations-container {
            height: 100%;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: center;
            align-items: center;
            row-gap: calc(1rem + 3vw);
            column-gap: 0;
            padding: calc(2rem + 8vw) 6rem calc(2rem + 8vw) 6rem;
            margin-top: 40%;

      }

      .stations-container h1 {
            position: fixed;
            margin-top: calc(20px + 10vh);
            margin-left: calc(20px + 2vw);
            top: 0;
            left: 0;
            font-style: italic;
            width: 80%;
            letter-spacing: 0px;
            font-size: 2rem;
            color: white;
      }
}