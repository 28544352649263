ul.navbar-items {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      height: 100%;
      padding-right: 20px;
      gap: 80px;
      padding-left: 60px;
}

.navbar-link {
      color: black;
      scale: 1;
      text-decoration: none;
}

.navbar-link::before {
      content: "";
      position: absolute;
      display: block;
      height: 3px;
      width: 0;
      background-color: black;
      transition: width 0.3s ease-in-out;
      margin-top: 18px;
}

.navbar-link:hover::before {
      width: 2%;
}


@media screen and (max-width: 1024px) {

      ul.navbar-items {
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            height: 100%;
            padding-right: 20px;
            gap: calc(4rem + 1vw);
            padding-left: 40px;
      }


      .navbar-link:hover::before {
            width: 3%;
      }


}

@media screen and (max-width: 768px) {



      ul.navbar-items {
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            height: 100%;
            padding-right: 20px;
            gap: calc(1rem + 1.5vw);
            padding-left: 40px;
      }


}


@media screen and (max-width: 720px) {

      ul.navbar-items {
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            height: 100%;
            padding-right: 20px;
            gap: calc(1rem + 0.5vw);
            padding-left: 40px;
            font-size: 0.8rem;
      }

      .navbar-link:hover::before {
            width: 2%;
            margin-top: 14px;
      }


}

@media screen and (max-width: 512px) {

      ul.navbar-items {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            list-style: none;
            height: 100%;
            padding-right: 20px;
            gap: 40px;
            padding-left: 30px;
            font-size: 1.4rem;
      }

      .navbar-link::before {
            content: "";
            position: absolute;
            display: block;
            height: 5px;
            width: 0;
            background-color: #FF90E8;
            transition: width 0.3s ease-in-out;
            margin-top: 35px !important;
      }

      .navbar-link {
            color: #FF90E8 !important;
      }

      .navbar-link:hover::before {
            width: 20%;
      }

}

@media screen and (max-width: 414px) {

      ul.navbar-items {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            list-style: none;
            height: 100%;
            padding-right: 20px;
            gap: 40px;
            padding-left: 30px;
            font-size: 1.4rem;
            color: #FF90E8 !important;
            

      }

      .navbar-link {
            color: #FF90E8 !important;
      }

      .navbar-link::before {
            content: "";
            position: absolute;
            display: block;
            height: 5px;
            width: 0;
            transition: width 0.3s ease-in-out;
            margin-top: 35px !important;
      }

      .navbar-link:hover::before {
            width: 20%;
      }

}